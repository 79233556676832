<template>
  <b-modal
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer=""
    size="md"
    :title="$t('payments.titles.debitBalance')"
    @hidden="handleClose"
    @show="getBalance()"
  >
    <b-container class="p-0">
      <b-form-row>
        <mask-input
          v-model="amount"
          mask-label="Saldo na Carteira"
          :prepend="$t('payments.labels.currency')"
          parent-class="col-md-5 col-sm-12"
          disabled
          :mask="['####,##', '###,##', '##,##', '#,##']"
        />
      </b-form-row>
      <b-form-row>
        <mask-input
          v-model="amountWithDiscount"
          mask-label="Valor do Lançamento"
          :prepend="$t('payments.labels.currency')"
          parent-class="col-md-5 col-sm-12"
          data-test="modal-transaction__input-amount-paid"
          disabled :mask="['####,##', '###,##', '##,##', '#,##']"
        />
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('payments.labels.paymentComments')"
          label-for="transaction-internal-comments"
          class="col-md-12 col-sm-12"
        >
          <b-form-textarea
            v-model="useCreditInternalComments"
            type="text"
            :rows="1"
            :max-rows="4"
          />
        </b-form-group>
      </b-form-row>
      <div class="float-right pt-2">
        <b-button
          class="ml-2"
          data-test="modal-transaction__btn-save"
          @click="$emit('closeModalDebitBalance')"
        >
          Cancel
        </b-button>

        <b-button
          variant="primary"
          class="ml-2"
          data-test="modal-transaction__btn-save"
          @click="confirmDebitCredits()"
        >
          Confirmar
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import DRIVER_GET_BALANCE from '@graphql/driver/queries/get-balance.gql';
import DEBIT_BALANCE from '@graphql/payment/mutations/debit-balance.gql'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ModalDebitBalance',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    modalData: {
      type: Object
    },
  },
  data () {
    return {
      useCreditInternalComments: '',
      amount: 0,
      amountWithDiscount: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      }
    },
    setDiscount: function () {
      return (this.modalData.amount_reference - this.modalData.discount);
    },
  },
  mounted () {
    this.amountWithDiscount = this.setDiscount;
  },
  methods: {
    ...mapActions('driver', ['clearHeader', 'header']),
		loadProfile() {
      this.clearHeader();
      this.header({
        id: this.modalData.driver,
      });
		},
    async getBalance () {
      try {
        const response = await this.$apollo.query({
          query: DRIVER_GET_BALANCE,
          variables: {
            driver: this.modalData.driver
          },
        });
        this.amount = response.data.getBalance.balance_amount
      } catch (err) {
        console.error(err);
      }
    },
    confirmDebitCredits () {
      const amountPaidFormatted = this.amountWithDiscount.replace(/[.,]/g, '');

      this.$apollo.mutate({
        mutation: DEBIT_BALANCE,
        variables: {
          transaction_id: this.modalData.id,
          invoice_id: this.modalData.invoice,
          value: parseInt(amountPaidFormatted),
          blame: this.attributes.email,
          kind: 'MONEY',
          internal_comments: `[ ${this.attributes.email}
            @${this.$moment().format('DD/MM/YYYY HH:mm')} ] -- ${this.useCreditInternalComments}\n\n`,
        }
      })
        .then(() => {
          this.loadProfile()
          this.$emit('successUseCredits')
          this.$emit('closeModalDebitBalance')
        })
        .catch(() => {
          this.$emit('errorDebitCredits')
        })
    },
    handleClose() {
      this.$emit('change', false);
    }
  }
}
</script>
